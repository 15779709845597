
.close-btn {
    margin-left: 100%;
}
.edit-btn {
    padding: 0 8px 0 8px !important;
}

.save-btn {
    margin-left: -10px;
}

.mr-13 {
    min-width: 48%;
}

.form-save {
    max-width: fit-content;
    margin: 10px 10% 0 0;
}

.v-text-field .v-input__control .v-input__slot {
    min-height: 41px !important;
    display: flex !important;
}
